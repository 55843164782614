<template>
  <div class="container">
    <TopHeader title="访客邀请"></TopHeader>
    <div class="tabs-content">
      <van-tabs v-model="active" @click="handleChangeTabs">
        <van-tab name="first" title="全部"></van-tab>
        <van-tab name="second" title="待审批"></van-tab>
        <van-tab name="third" title="审核通过"></van-tab>
        <van-tab name="fourth" title="失效"></van-tab>
      </van-tabs>
    </div>
    <div class="select-container">
      <el-select
        v-model="value"
        placeholder="请选择"
        class="select-content"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="add-container">
      <div class="add-content">
        <van-button class="add-btn" size="mini" @click="toPage('InviteAdd')">邀请访客</van-button>
      </div>
    </div>
    <div class="main-container" v-if="visitList && visitList.length">
      <div class="main-content" v-for="(item,index) in visitList" :key="index" @click="toDetail(item)">
        <div class="main-title-items">
          <div class="main-title-items-title">访客姓名</div>
          <div class="main-title-items-text">{{item.visitName}}</div>
        </div>
        <CommonBorder></CommonBorder>
        <div class="main-items">
          <div class="main-items-title">来访时间</div>
          <div class="main-items-text">{{ item.start }}</div>
        </div>
        <div class="main-items">
          <div class="main-items-title">离开时间</div>
          <div class="main-items-text">{{ item.end ? item.end : '无' }}</div>
        </div>
        <div class="main-items">
          <div class="main-items-title">电话</div>
          <div class="main-items-text">{{ item.phone }}</div>
        </div>
        <div class="main-items">
          <div class="main-items-title">到访原因</div>
          <div class="main-items-text">{{ reasonMap[item.reason] }}</div>
        </div>
        <div class="main-items">
          <div class="main-items-title">状态</div>
          <div class="main-items-text">
            <van-tag v-if="item.status === 1 || item.status === 2" color="#FA5050" size="medium">{{ statusMap[item.status] }}</van-tag>
            <van-tag v-if="item.status === 3" color="#C7000B" size="medium">{{ statusMap[item.status] }}</van-tag>
            <van-tag v-if="item.status === 4 || item.status === 5" color="#ed3944" size="medium">{{ statusMap[item.status] }}</van-tag>
            <van-tag v-if="item.status === 6 || item.status === 7 || item.status === 8" text-color="#666" color="#E5E5E5" size="medium">{{ statusMap[item.status] }}</van-tag>
          </div>
        </div>
        <div class="operate-content" v-if="item.status===3 &&  verify2Id===item.verify1Id">
          <van-button class="btn mr12" size="mini" plain style="background: #fff;color: #FA5050;border-color: #FA5050;" @click.stop="toEditFn(item)">修改预约</van-button>
          <van-button class="btn" size="mini" plain style="background: #FA5050;color: #fff;border-color: #FA5050;" @click.stop="auditVisitFn(item.id,2,item.status)">取消预约</van-button>
        </div>
        <div class="operate-content" v-if="item.status===1 && item.userId===item.verify1Id || item.status===2 && item.verify2Id && verify2Id===item.verify2Id">
          <van-button class="btn mr12" size="mini" plain style="background: #fff;color: #FA5050;border-color: #FA5050;" @click.stop="auditVisitFn(item.id,2,item.status)">驳回</van-button>
          <van-button class="btn" size="mini" plain style="background: #FA5050;color: #fff;border-color: #FA5050;" @click.stop="auditVisitFn(item.id,1)">通过</van-button>
        </div>
      </div>
    </div>
    <div class="empty-container" v-else>
      <img class="empty-img" src="@/assets/images/noneImg.png" alt="" />
      <div class="empty-text">暂无内容</div>
    </div>
    <div>
      <van-dialog v-model="showOperate" title="提示" show-cancel-button confirm-button-color="#FA5050" confirm-button-text="确定" cancel-button-text="取消" cancel-button-color="#999999" @cancel="handleCancel" @confirm="handleConfirm">
        <div v-if="status2 === 3" class="confirm-dialog">请确认是否"取消预约"?</div>
        <div v-if="status2 !== 3" class="confirm-dialog">确认是否"驳回"?</div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import TopHeader from '@/components/topHeader/index.vue'
import CommonBorder from '@/components/commonBorder/index.vue'
import { getVisitListAPI, auditVisitAPI } from "@/api/invite";
import { Toast } from "vant";
export default {
  name: "InviteList",
  components: {
    TopHeader,
    CommonBorder
  },
  data() {
    return {
      cancel,
      active: 'first',
      consumeList: [],
      visitList: [],
      pages: {
        current: 1,
        size: 10,
        total: 0,
        status: "",
      },
      verify2Id: "",
      value: "1",
      options: [
        {
          value: "1",
          label: "近一周",
        },
      ],
      reasonMap: {
        0: "开会",
        1: "面试",
        2: "拜访",
        3: "合作",
        4: "洽谈",
        5: "访友",
        6: "推销",
        7: "其他",
      },
      statusMap: {
        1: "待审核",
        2: "待审核",
        3: "审核通过",
        4: "未通过",
        5: "未通过",
        6: "失效",
        7: "失效",
        8: "失效",
      },
      showOperate: false,
      id: null,
      status: null,
      status2: null,
    };
  },
  created() {
    this.getVisitListFn();
    this.verify2Id = JSON.parse(sessionStorage.getItem("personInfo")).id;
  },
  methods: {
    handleChangeTabs(name) {
      let status = name === 'first' ?  '' : name === 'second' ? '0'  : name === 'third' ? '1' : '2'
      this.getList(status)
    },
    toPage(name) {
      this.$router.push({ name });
    },
    toBack() {
      this.$router.back();
    },
    async getVisitListFn() {
      const res = await getVisitListAPI({ ...this.pages });
      if (res.code === 0) {
        this.visitList = res.data.records;
        this.pages.current = res.data.current;
        this.pages.size = res.data.size;
        this.pages.total = res.data.total;
      }
    },
    async auditVisitFn(id, status, status2) {
      if (status === 2) {
        this.showOperate = true;
        this.id = id;
        this.status = status;
        this.status2 = status2;
      } else {
        const res = await auditVisitAPI(status, id);
        if (res.code === 0) {
          Toast.success({
            message: "已通过!",
          });
          this.getVisitListFn();
        }
      }
    },
    getList(status) {
      this.pages.status = status;
      this.pages.current = 1;
      this.pages.size = 10;
      this.pages.total = 0;
      this.getVisitListFn();
    },
    toDetail(item) {
      this.$router.push({
        name: "InviteDetail",
        query: { item: JSON.stringify(item) },
      });
    },
    toEditFn(item) {
      this.$router.push({
        name: "InviteEdit",
        query: { item: JSON.stringify(item) },
      });
    },
    paginationChangeFn(val) {
      this.pages.current = val;
      // this.visitList = [];
      this.getVisitListFn();
      window.scrollTo(0, 0);
    },

    async handleConfirm() {
      const res = await auditVisitAPI(this.status, this.id);
      if (res.code === 0) {
        this.showOperate = false;
        Toast.success({
          message: this.status2 === 3 ? "已取消!" : "已驳回!",
        });
        this.getVisitListFn();
      }
    },

    handleCancel() {
      this.showOperate = false;
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .tabs-content{
    height: 45px;
  }
  .select-container{
    height: 45px;
    background: #fff;
    line-height: 45px;
    .select-content{
      width:100px;
      margin-left:16px;
    }
  }
  .add-container{
    width: 100%;
    height: 60px;
    background: #f1f1f1;
    .add-content{
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      padding: 10px 16px 0;
      .add-btn{
        width: 100%;
        height:45px;
        background: #FA5050;
        border-color: #FA5050;
        font-size: 18px;
        color: #fff;
        border-radius: 4px;
      }
    }
  }
  .main-container{
    flex:1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
    padding: 10px 16px 0;
    .main-content{
      background: #fff;
      margin-bottom: 16px;
      border-radius: 8px;
      .main-title-items{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 16px;
        height:50px;
        .main-title-items-title{
          width: 80px;
          color: #333;
          font-weight: bold;
        }
        .main-title-items-text{
          flex: 1;
          text-align: right;
          color: #333;
          font-weight: bold;
        }
      }
      .main-items{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 16px;
        height:44px;
        .main-items-title{
          color: #333;
        }
        .main-items-text{
          color: #666;
        }
      }
      .operate-content{
        width: 100%;
        text-align: right;
        margin-top: 15px;
        box-sizing: border-box;
        padding: 0 16px 16px;
        .btn{
          width: 80px;
          height:33px;
          font-size: 16px;
          box-sizing: border-box;
          border-radius: 4px;
        }
        .mr12{
          margin-right: 12px;
        }
      }
    }
  }
  .empty-container{
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .empty-img{
      width: 280px;
      height: 150px;
      margin: 0px auto 20px;
    }
    .empty-text{
      text-align: center;
      color: #666;
      font-size: 16px;
    }
  }
  .confirm-dialog{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: #666;
    margin-bottom: 10px;
  }
}

::v-deep .van-tab{
  font-size: 16px;
}

::v-deep .van-tabs__line{
  background: #C7000B;
  width: 60px;
}

::v-deep .van-tab--active{
color: #C7000B;
}

::v-deep .el-input__inner{
  border: 0;
  font-size: 16px;
}

::v-deep .van-dialog{
  height: 160px;
}

::v-deep .van-dialog__header{
  color: #333 !important;
}
</style>
